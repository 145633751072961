define('ui/components/new-balancer/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/utils/constants'], function (exports, _ember, _uiMixinsNewOrEdit, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    intl: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    service: null,
    editing: null,
    existing: null,
    allHosts: null,
    allCertificates: null,
    upgradeImage: null,

    isGlobal: null,
    isRequestedHost: null,
    upgradeOptions: null,
    hasUnsupportedPorts: false,

    // Errors from components
    ruleErrors: null,
    schedulingErrors: null,
    scaleErrors: null,

    primaryResource: _ember['default'].computed.alias('service'),
    launchConfig: _ember['default'].computed.alias('service.launchConfig'),

    init: function init() {
      this._super.apply(this, arguments);
      this.labelsChanged();
      this.get('service').initPorts();
      this.updatePorts();
    },

    actions: {
      done: function done() {
        this.sendAction('done');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      },

      setScale: function setScale(scale) {
        this.set('service.scale', scale);
      },

      setLabels: function setLabels(section, labels) {
        this.set(section + 'Labels', labels);
      },

      setUpgrade: function setUpgrade(upgrade) {
        this.set('upgradeOptions', upgrade);
      },

      setGlobal: function setGlobal(bool) {
        this.set('isGlobal', bool);
      }
    },

    headerLabel: (function () {
      var k = undefined;
      if (this.get('needsUpgrade')) {
        k = 'newBalancer.header.upgrade';
      } else if (this.get('existing')) {
        k = 'newBalancer.header.edit';
      } else {
        k = 'newBalancer.header.add';
      }

      return this.get('intl').t(k);
    }).property('intl._locale', 'needsUpgrade', 'isService', 'isVm', 'service.secondaryLaunchConfigs.length'),

    // ----------------------------------
    // Ports
    // ----------------------------------
    updatePorts: function updatePorts() {
      var rules = this.get('service.lbConfig.portRules') || [];
      var publish = [];
      var expose = [];

      // Set ports and publish on the launch config
      rules.forEach(function (rule) {
        // The inner one eliminates null/undefined, then the outer one
        // converts integers to string (so they can be re-parsed later)
        var srcStr = ((rule.get('sourcePort') || '') + '').trim();
        var src = parseInt(srcStr, 10);
        if (!src || src < 1 || src > 65535) {
          return;
        }

        var entry = src + ":" + src + "/" + rule.get('ipProtocol');
        if (rule.get('access') === 'public') {
          // Source IP applies only to public rules
          var ip = rule.get('sourceIp');
          if (ip) {
            // IPv6
            if (ip.indexOf(":") >= 0 && ip.substr(0, 1) !== '[') {
              entry = '[' + ip + ']:' + entry;
            } else {
              entry = ip + ':' + entry;
            }
          }

          publish.push(entry);
        } else {
          expose.push(entry);
        }
      });

      this.get('service.launchConfig').setProperties({
        ports: publish.uniq(),
        expose: expose.uniq()
      });
    },

    shouldUpdatePorts: (function () {
      _ember['default'].run.once(this, 'updatePorts');
    }).observes('service.lbConfig.portRules.@each.{sourceIp,sourcePort,access,protocol}'),

    validateRules: function validateRules() {
      var intl = this.get('intl');
      var rules = this.get('service.lbConfig.portRules');
      var errors = [];
      var seen = {};

      // Set ports and publish on the launch config
      // And also do a bunch of validation while we're here
      rules.forEach(function (rule) {
        // The inner one eliminates null/undefined, then the outer one
        // converts integers to string (so they can be re-parsed later)
        var srcStr = ((rule.get('sourcePort') || '') + '').trim();
        var tgtStr = ((rule.get('targetPort') || '') + '').trim();
        var wgtStr = ((rule.get('weight') || '') + '').trim();

        if (!srcStr) {
          errors.push(intl.t('newBalancer.error.noSourcePort'));
          return;
        }

        var src = parseInt(srcStr, 10);
        if (!src || src < 1 || src > 65535) {
          errors.push(intl.t('newBalancer.error.invalidSourcePort', { num: srcStr }));
        } else if (!tgtStr) {
          tgtStr = srcStr;
        }

        var tgt = parseInt(tgtStr, 10);
        if (!tgt || tgt < 1 || tgt > 65535) {
          errors.push(intl.t('newBalancer.error.invalidTargetPort', { num: tgtStr }));
          return;
        }

        if (rule.isSelector) {
          if (rule.region || rule.environment) {
            var wgt = parseInt(wgtStr, 10);
            if (isNaN(wgt) || wgt < 0) {
              errors.push(intl.t('newBalancer.error.invalidWeight', { num: wgt }));
              return;
            }
            rule.setProperties({
              weight: wgt
            });
          }

          if (!rule.region && rule.environment) {
            errors.push(intl.t('newBalancer.error.missingRegion'));
            return;
          }
          if (rule.region && !rule.environment) {
            errors.push(intl.t('newBalancer.error.missingEnvironment'));
            return;
          }
        }

        var sourceIp = rule.get('sourceIp');
        var key = undefined;
        if (sourceIp) {
          key = '[' + sourceIp + ']:' + src;
        } else {
          key = '[0.0.0.0]:' + src;
        }

        var access = rule.get('access');
        var id = access + '-' + rule.get('protocol') + '-' + src;

        if (seen[key]) {
          if (seen[key] !== id) {
            errors.push(intl.t('newBalancer.error.mixedPort', { num: src }));
          }
        } else {
          seen[key] = id;
        }

        if (!rule.get('serviceId') && !rule.get('selector')) {
          errors.push(intl.t('newBalancer.error.noTarget'));
        }

        // Make ports always numeric
        rule.setProperties({
          sourcePort: src,
          targetPort: tgt
        });
      });

      this.set('ruleErrors', errors);
    },

    needsUpgrade: (function () {
      function arrayToStr(map) {
        map = map || {};
        var out = [];
        var keys = Object.keys(map);
        keys.sort();
        keys.forEach(function (key) {
          out.push(key + '=' + map[key]);
        });

        return JSON.stringify(out);
      }

      function removeKeys(map, keys) {
        map = map || {};
        keys.forEach(function (key) {
          delete map[key];
        });

        return map;
      }

      if (!this.get('editing')) {
        return false;
      }

      if (this.get('upgradeImage') + '' === 'true') {
        return true;
      }

      // Label arrays are updated one at a time and make this flap,
      // so ignore them until they're all set
      if (!this.get('labelsReady')) {
        return false;
      }

      var old = removeKeys(this.get('existing.launchConfig.labels'), _uiUtilsConstants['default'].LABELS_TO_IGNORE);
      var neu = removeKeys(this.get('service.launchConfig.labels'), _uiUtilsConstants['default'].LABELS_TO_IGNORE);
      return arrayToStr(old) !== arrayToStr(neu);
    }).property('editing', 'upgradeImage', 'service.launchConfig.labels'),

    upgradeInfo: (function () {
      var from = (this.get('existing.launchConfig.imageUuid') || '').replace(/^docker:/, '');
      var to = (this.get('service.launchConfig.imageUuid') || '').replace(/^docker:/, '');

      if (this.get('upgradeImage') + '' === 'true') {
        return _ember['default'].Object.create({
          from: from,
          to: to
        });
      }
    }).property('existing.launchConfig.imageUuid', 'service.launchConfig.imageUuid'),

    // ----------------------------------
    // Labels
    // ----------------------------------
    userLabels: null,
    scaleLabels: null,
    schedulingLabels: null,
    labelsReady: false,

    labelsChanged: (function () {
      _ember['default'].run.once(this, 'mergeLabels');
    }).observes('userLabels.@each.{key,value}', 'scaleLabels.@each.{key,value}', 'schedulingLabels.@each.{key,value}'),

    mergeLabels: function mergeLabels() {
      var user = this.get('userLabels');
      var scale = this.get('scaleLabels');
      var scheduling = this.get('schedulingLabels');
      var out = {};

      (this.get('userLabels') || []).forEach(function (row) {
        out[row.key] = row.value;
      });
      (this.get('scaleLabels') || []).forEach(function (row) {
        out[row.key] = row.value;
      });
      (this.get('schedulingLabels') || []).forEach(function (row) {
        out[row.key] = row.value;
      });

      var config = this.get('launchConfig');
      if (config) {
        this.set('launchConfig.labels', out);
      }

      this.set('labelsReady', user && scale && scheduling);
    },

    editLabel: (function () {
      return this.get('needsUpgrade') ? 'action.upgrade' : 'action.edit';
    }).property('needsUpgrade'),

    // ----------------------------------
    // Save
    // ----------------------------------
    willSave: function willSave() {
      this.validateRules();
      return this._super();
    },

    validate: function validate() {
      var intl = this.get('intl');

      var errors = [];
      // Errors from components
      errors.pushObjects(this.get('ruleErrors') || []);
      errors.pushObjects(this.get('schedulingErrors') || []);
      errors.pushObjects(this.get('scaleErrors') || []);

      if (!this.get('service.launchConfig.ports.length') && !this.get('service.launchConfig.expose.length')) {
        errors.push(intl.t('newBalancer.error.noRules' /*just right*/));
      }

      if (this.get('service.lbConfig.needsCertificate') && !this.get('service.lbConfig.defaultCertificateId')) {
        errors.push(intl.t('newBalancer.error.needsCertificate'));
      } else if (!this.get('service.lbConfig.needsCertificate')) {
        this.set('service.lbConfig.defaultCertificateId', null);
        this.set('service.lbConfig.certificateIds', []);
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      // Generic validation
      this._super();
      errors = this.get('errors') || [];

      errors.pushObjects(this.get('service').validationErrors());

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    },

    doSave: function doSave() {
      var _this = this,
          _arguments = arguments;

      if (this.get('editing')) {
        var _ret = (function () {
          var service = _this.get('service');
          return {
            v: _this._super.apply(_this, _arguments).then(function () {
              if (_this.get('needsUpgrade')) {
                return service.waitForAction('upgrade').then(function () {
                  return service.doAction('upgrade', {
                    inServiceStrategy: {
                      batchSize: _this.get('upgradeOptions.batchSize'),
                      intervalMillis: _this.get('upgradeOptions.intervalMillis'),
                      startFirst: _this.get('upgradeOptions.startFirst'),
                      launchConfig: service.get('launchConfig')
                    }
                  });
                });
              }
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        return this._super.apply(this, arguments);
      }
    },

    doneSaving: function doneSaving() {
      this.send('done');
    }
  });
});